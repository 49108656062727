import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import auv from "../../images/AUV-timeline.jpg"
import ocean from "../../images/ocean.jpg"
import "../../components/prizes.css"
import { StaticImage } from "gatsby-plugin-image"

// images

const Oceanobserving = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">
                Powering the Blue Economy™: Ocean Observing Prize
              </h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The Powering the Blue Economy™: Ocean Observing Prize challenges
                innovators to integrate marine renewable energy with ocean
                observation platforms, ultimately revolutionizing our ability to
                collect the data needed to understand, map, and monitor the
                ocean.
              </p>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                This joint prize between the Water Power Technologies Office
                (WPTO) at the U.S. Department of Energy (DOE) and NOAA’s
                Integrated Ocean Observing System (IOOS®) Office seeks to
                develop new technologies that can help fill the data gaps making
                it difficult to realize the full potential of the Blue Economy.
                The Ocean Observing Prize includes a series of competitions,
                with millions of dollars in awards, to encourage rapid
                innovation in the fields of marine energy and ocean
                observations. The prize began with the DISCOVER Competition and
                innovators are now competing in the DEVELOP Competition. The
                Pacific Northwest National Laboratory and the National Renewable
                Energy Laboratory (NREL) are supporting DOE and NOAA in the
                development and administration of the prize.
              </p>

              <p style={{ textAlign: "justify" }}>
                <a href="" style={{ color: "white" }}>
                  Learn more about this prize
                </a>
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/oceanobserving">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace" style={{ padding: "5px" }}></span>
                <div className="has-text-centered">
                  <a
                    href="../../Ocean_Oberving_Prize_Sponsorship_Package.pdf"
                    target="_blank"
                  >
                    <button type="button" className="btns">
                      Learn how organizations can get involved{" "}
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="eager"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <p className="pcol-fz">
            The Ocean Observing Prize is a series of competitions with cash
            awards to incentivize accelerated advancement of ideas, from concept
            to demonstration. The first competition, the DISCOVER Competition,
            closed on February 12, 2020. Competitors submitted novel concepts
            that integrate ocean observing technologies with marine energy
            systems to address end-user needs across five broad themes: (1)
            Unmanned Vehicles; (2) Communications and Underwater Navigation; (3)
            Extreme Environments; (4) Buoys, Floats, and Tags; and (5) Blue Sea
            Ideas (i.e., other novel themes not listed).
          </p>
          <p className="pcol-fz">
            Following the DISCOVER Competition, the DEVELOP Competition focuses
            on a single theme, hurricane monitoring, and challenges contestants
            to develop their ideas into a functioning prototype through three
            contests: DESIGN, BUILD, and SPLASH. Together, the DISCOVER and
            DEVELOP Competitions will award up to $3 million in cash and other
            in-kind awards.
          </p>
          <p className="pcol-fz">
            The Ocean Observing Prize is repeatable, and it is expected that
            future iterations may focus on other themes. Through this
            competition, innovators will be able to tap into DOE and NOAA’s
            network of National Laboratories, energy incubators and
            accelerators, subject matter experts, and other resources across the
            nation to build novel technologies that collect critical ocean data.
          </p>
          <StaticImage
            src="../../images/AUV-timeline.jpg"
            className="img-styl"
            alt="auv timeline"
            loading="lazy"
          />
          <p className="pcol-fz">
            The American-Made Challenges Prize platform, administered by NREL,
            brings together a world-class research base with an unparalleled
            entrepreneurial support system—consisting of pioneering university
            teams, dozens of energy incubators, and 16 National Laboratories—to
            create a sweeping portfolio of innovations primed for private
            investment and commercial scale-up.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">DEVELOP Competition</h1>
          <hr />
          <p className="pcol-fz">
            The second contest in the DEVELOP Competition, BUILD, is underway.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Submission Open: </b>April 19, 2021
              </li>
              <li>
                <b>Tank Testing:</b> Anticipated January-February 2022
              </li>
              <li>
                <b>Winner Announcement and Awards:</b> Anticipated February 2022
              </li>
            </ul>

            <b class="pcol-fz">
              All dates are subject to change including contest openings,
              deadlines, and announcements.
            </b>
            <p>
              Sign up for updates on our{" "}
              <a href="https://www.herox.com/oceanobserving">
                {" "}
                HeroX challenge page.
              </a>
            </p>
            <hr />
          </div>
          <div>
            <h1 className="mac_h1">Follow the Changes</h1>
            <hr />
            <b class="pcol-fz">
              All are welcome and encouraged to join the American-Made Challenge
              Network and be a part of the ground-breaking change that will
              accelerate innovation in ocean observing and marine renewable
              energy technologies.
            </b>
          </div>
          <hr />
        </div>
        <div className="container">
          <h3 className="h3ps"> Solutions that help shape the future </h3>
          <StaticImage src="../../images/ocean.jpg" className="img" alt="ocean"  loading="lazy"/>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/oceanobserving">
              <button type="button" className="btns">
                Follow the Challenge?
              </button>
            </a>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Oceanobserving
